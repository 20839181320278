import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remote-modal"
export default class extends Controller {
  connect() {
    console.log('modal')
    let button = document.querySelector('.modal-btn');
    let backdrop = this.element.querySelector('::backdrop')

    console.log(backdrop)
    
    button.addEventListener('click', () => {
      this.show();
    })

    this.element.addEventListener('click', (e) => {
      if(e.target.className == 'dialog-modal' && e.target.className !== "dialog__container"){
        this.element.close();
      }
    })
  }

  show() {
    this.element.showModal();
  }
}
